// import i18n from '@/locales/index'
// const t = i18n.global.t

import { useFanyi } from '@/plugin/fanyi'
import { ElMessage, ElLoading } from 'element-plus'

const { locale } = useFanyi()
const { f } = useFanyi()

export const formatQianfenwei = (x) => {
  if (isNaN(x)) {
    return 0
  }
  const reg = /(\d)(?=(\d{3})+$)/g
  return x.toString().replace(reg, '$1,')
}

export const formatQianfenwei_old = (x) => {
  x += ''
  let re = new RegExp(/^(\d)*$/)
  if (!re.test(x.replaceAll(',', ''))) {
    // alert(f('p153_t100', '')')
    return false
  }
  let num = parseInt(x.replaceAll(',', ''))
  if (isNaN(num)) {
    return false
  }
  const reg = /(\d)(?=(\d{3})+$)/g
  x = num.toString().replace(reg, '$1,')
  return x
}

export const clearQianfenwei = (x) => {
  x += ''
  x = x.replaceAll(',', '')
  return x
}

export const getWidth = () => {
  if (locale.value == 'zh-cn') {
    return '90'
  } if (locale.value == 'jp') {
    return '140'
  }
  if (locale.value == 'en') {
    return '140'
  }
}





export const resourceTypeList = [
  { label: f('p153_t110', '个人'), value: 1 },
  { label: f('p153_t120', '协会'), value: 2 },
  { label: f('p153_t130', '联赛'), value: 3 },
  { label: f('p153_t140', '赛事'), value: 4 },
  { label: f('p153_t150', '俱乐部/队伍'), value: 5 },
  { label: f('p153_t160', '其他'), value: 9 }
]

export const getTypeText = (val) => {
  const r = resourceTypeList.filter((item) => item.value == val)
  if (r && r.length > 0) {
    return resourceTypeList.filter((item) => item.value == val)[0].label
  } else {
    return ''
  }
}

export const getTypeTexts = (vals) => {
  if (!vals) {
    return ''
  }
  const r = []
  vals.forEach((val) => {
    r.push(getTypeText(val))
  })

  return r.join(',')
}

export const activityList = {
  person: [
    // 个人
    { label: f('p153_t170', '品牌使用个人肖像进行推广的权利'), value: 1001 },
    { label: f('p153_t180', '拍摄品牌的电视或视频广告'), value: 1002 },
    { label: f('p153_t190', '拍摄品牌的平面广告'), value: 1003 },
    { label: f('p153_t200', '参加品牌线下推广活动'), value: 1004 },
    {
      label: f('p153_t210', '与运动员在社交媒体及电商等互联网平台交互推广的权利'), value: 1005
    },
    { label: f('p153_t220', '其他'), value: 9999 }
  ],
  game: [
    // 联赛/赛事
    {
      label:
        f('p153_t230', '品牌使用IP元素（logo，集体肖像权，赛事名称，吉祥物，奖杯等）进行推广的权利'), value: 2001
    },
    {
      label:
        f('p153_t240', '比赛及活动现场品牌曝光（场地广告，球衣广告，比赛相关设备广告等）的权利'),
      value: 2002
    },
    { label: f('p153_t250', '在比赛现场进行品牌推广线下活动的权利'), value: 2003 },
    { label: f('p153_t260', '与赛事在社交媒体及电商等互联网平台交互推广的权利'), value: 2004 },
    {
      label:
        f('p153_t270', '其他和比赛相关的品牌曝光的机会（新闻发布背景板，训练场地广告板，官方刊物，官网等）'),
      value: 2005
    },
    { label: f('p153_t280', '贵宾款待及票证的权利'), value: 2006 },
    { label: f('p153_t290', '在赛事直播平台中优先购买广告的权利'), value: 2007 },
    { label: f('p153_t300', '个性化定制赞助权益的权利'), value: 2008 },
    { label: f('p153_t310', '其他'), value: 9999 }
  ],
  club: [
    // 俱乐部
    {
      label:
        f('p153_t320', '品牌使用IP元素（logo，集体肖像权，俱乐部名称，吉祥物等）进行推广的权利'),
      value: 3001
    },
    { label: f('p153_t330', '俱乐部比赛及训练用服装上展现品牌的权利'), value: 3002 },
    {
      label: f('p153_t340', '比赛现场品牌曝光（场地广告，球衣广告，比赛相关设备广告等）的权利'),
      value: 3003
    },
    { label: f('p153_t350', '在俱乐部主场比赛现场进行品牌推广线下活动的权利'), value: 3004 },
    {
      label: f('p153_t360', '与俱乐部在社交媒体及电商等互联网平台交互推广的权利'), value: 3005
    },
    { label: f('p153_t370', '邀请俱乐部运动员代表参加品牌线下活动的权利'), value: 3006 },
    { label: f('p153_t380', '贵宾款待及票证的权利'), value: 3007 },
    { label: f('p153_t390', '个性化定制赞助权益的权利'), value: 3008 },
    { label: f('p153_t400', '其他'), value: 9999 }
  ],
  union: [
    // 协会
    {
      label:
        f('p153_t410', '品牌使用IP元素（logo，集体肖像权，协会名称，吉祥物等）进行推广的权利'),
      value: 4001
    },
    {
      label:
        f('p153_t420', '协会主管赛事现场品牌曝光（场地广告，球衣广告，比赛相关设备广告等）的权利'),
      value: 4002
    },
    { label: f('p153_t430', '在协会主管赛事现场进行品牌推广线下活动的权利'), value: 4003 },
    {
      label: f('p153_t440', '与协会所管赛事或运动员在社交媒体及电商等互联网平台交互推广的权利'), value: 4004
    },
    { label: f('p153_t450', '邀请协会所属运动员代表参加品牌线下活动的权利'), value: 4005 },
    { label: f('p153_t460', '贵宾款待及票证的权利'), value: 4006 },
    { label: f('p153_t470', '个性化定制赞助权益的权利'), value: 4007 },
    { label: f('p153_t480', '其他'), value: 9999 }
  ],

  person2: [
    // 个人
    { label: f('p153_t6010', '年框合作'), value: 5001 },
    { label: f('p153_t6020', '单次发布合作'), value: 5002 },
    { label: f('p153_t6030', '运动员出席品牌方活动'), value: 5003 },
    { label: f('p153_t6040', '运动员参与赞助商平面及视频拍摄'), value: 5004 },
    {
      label: f('p153_t6050', '肖像授权合作'), value: 5005
    },
    {
      label: f('p153_t6060', '运动员以嘉宾身份出席品牌方直播'), value: 5006
    },


    // {
    //   label: f('p153_t6070', '接受实物赞助'), value: 5007
    // },


    // { label: f('p153_t220', '其他'), value: 9999 }
  ],
}

export const getActivityText = (val) => {
  let all = [
    ...activityList.club,
    ...activityList.union,
    ...activityList.game,
    ...activityList.person,
    ...activityList.person2,
  ]

  let find = all.filter((x) => x.value == val)
  if (find && find.length > 0) {
    return find[0].label
  }

}
export const country = [
  { name: f('p153_t13085', '日本'), areaCode: 81, value: 'c100' },
  { name: f('p153_t13000', '阿富汗'), areaCode: 93, value: 'c110' },
  { name: f('p153_t13001', '阿尔巴尼亚'), areaCode: 355, value: 'c120' },
  { name: f('p153_t13002', '阿尔及利亚'), areaCode: 213, value: 'c130' },
  { name: f('p153_t13003', '安道尔'), areaCode: 376, value: 'c140' },
  { name: f('p153_t13004', '安哥拉'), areaCode: 244, value: 'c150' },
  { name: f('p153_t13005', '安提瓜和巴布达'), areaCode: 1268, value: 'c160' },
  { name: f('p153_t13006', '阿根廷'), areaCode: 54, value: 'c170' },
  { name: f('p153_t13007', '亚美尼亚'), areaCode: 374, value: 'c180' },
  { name: f('p153_t13008', '澳大利亚'), areaCode: 61, value: 'c190' },
  { name: f('p153_t13009', '奥地利'), areaCode: 43, value: 'c200' },
  { name: f('p153_t13010', '阿塞拜疆'), areaCode: 994, value: 'c210' },
  { name: f('p153_t13011', '巴哈马'), areaCode: 1242, value: 'c220' },
  { name: f('p153_t13012', '巴林'), areaCode: 973, value: 'c230' },
  { name: f('p153_t13013', '孟加拉国'), areaCode: 880, value: 'c240' },
  { name: f('p153_t13014', '巴巴多斯'), areaCode: 1246, value: 'c250' },
  { name: f('p153_t13015', '白俄罗斯'), areaCode: 375, value: 'c260' },
  { name: f('p153_t13016', '比利时'), areaCode: 32, value: 'c270' },
  { name: f('p153_t13017', '伯利兹'), areaCode: 501, value: 'c280' },
  { name: f('p153_t13018', '贝宁'), areaCode: 229, value: 'c290' },
  { name: f('p153_t13019', '不丹'), areaCode: 975, value: 'c300' },
  { name: f('p153_t13020', '玻利维亚'), areaCode: 591, value: 'c310' },
  { name: f('p153_t13021', '波斯尼亚和黑塞哥维那'), areaCode: 387, value: 'c320' },
  { name: f('p153_t13022', '博茨瓦纳'), areaCode: 267, value: 'c330' },
  { name: f('p153_t13023', '巴西'), areaCode: 55, value: 'c340' },
  { name: f('p153_t13024', '文莱'), areaCode: 673, value: 'c350' },
  { name: f('p153_t13025', '保加利亚'), areaCode: 359, value: 'c360' },
  { name: f('p153_t13026', '布基纳法索'), areaCode: 226, value: 'c370' },
  { name: f('p153_t13027', '布隆迪'), areaCode: 257, value: 'c380' },
  { name: f('p153_t13028', '柬埔寨'), areaCode: 855, value: 'c390' },
  { name: f('p153_t13029', '喀麦隆'), areaCode: 237, value: 'c400' },
  { name: f('p153_t13030', '加拿大'), areaCode: 1, value: 'c410' },
  { name: f('p153_t13031', '佛得角'), areaCode: 238, value: 'c420' },
  { name: f('p153_t13032', '中非共和国'), areaCode: 236, value: 'c430' },
  { name: f('p153_t13033', '乍得'), areaCode: 235, value: 'c440' },
  { name: f('p153_t13034', '智利'), areaCode: 56, value: 'c450' },
  { name: f('p153_t13035', '中国'), areaCode: 86, value: 'c460' },
  { name: f('p153_t13198', '中国香港'), areaCode: 853, value: 'c2090' },
  { name: f('p153_t13197', '中国澳门'), areaCode: 852, value: 'c2080' },
  { name: f('p153_t13036', '哥伦比亚'), areaCode: 57, value: 'c470' },
  { name: f('p153_t13037', '科摩罗'), areaCode: 269, value: 'c480' },
  { name: f('p153_t13038', '刚果（金）'), areaCode: 243, value: 'c490' },
  { name: f('p153_t13039', '刚果（布）'), areaCode: 242, value: 'c500' },
  { name: f('p153_t13040', '哥斯达黎加'), areaCode: 506, value: 'c510' },
  { name: f('p153_t13041', '科特迪瓦'), areaCode: 225, value: 'c520' },
  { name: f('p153_t13042', '克罗地亚'), areaCode: 385, value: 'c530' },
  { name: f('p153_t13043', '古巴'), areaCode: 53, value: 'c540' },
  { name: f('p153_t13044', '塞浦路斯'), areaCode: 357, value: 'c550' },
  { name: f('p153_t13045', '捷克共和国'), areaCode: 420, value: 'c560' },
  { name: f('p153_t13046', '丹麦'), areaCode: 45, value: 'c570' },
  { name: f('p153_t13047', '吉布提'), areaCode: 253, value: 'c580' },
  { name: f('p153_t13048', '多米尼加'), areaCode: 1767, value: 'c590' },
  { name: f('p153_t13049', '多米尼加共和国'), areaCode: 1809, value: 'c600' },
  { name: f('p153_t13050', '东帝汶'), areaCode: 670, value: 'c610' },
  { name: f('p153_t13051', '厄瓜多尔'), areaCode: 593, value: 'c620' },
  { name: f('p153_t13052', '埃及'), areaCode: 20, value: 'c630' },
  { name: f('p153_t13053', '萨尔瓦多'), areaCode: 503, value: 'c640' },
  { name: f('p153_t13054', '赤道几内亚'), areaCode: 240, value: 'c650' },
  { name: f('p153_t13055', '厄立特里亚'), areaCode: 291, value: 'c660' },
  { name: f('p153_t13056', '爱沙尼亚'), areaCode: 372, value: 'c670' },
  { name: f('p153_t13057', '斯威士兰'), areaCode: 268, value: 'c680' },
  { name: f('p153_t13058', '埃塞俄比亚'), areaCode: 251, value: 'c690' },
  { name: f('p153_t13059', '斐济'), areaCode: 679, value: 'c700' },
  { name: f('p153_t13060', '芬兰'), areaCode: 358, value: 'c710' },
  { name: f('p153_t13061', '法国'), areaCode: 33, value: 'c720' },
  { name: f('p153_t13062', '加蓬'), areaCode: 241, value: 'c730' },
  { name: f('p153_t13063', '冈比亚'), areaCode: 220, value: 'c740' },
  { name: f('p153_t13064', '格鲁吉亚'), areaCode: 995, value: 'c750' },
  { name: f('p153_t13065', '德国'), areaCode: 49, value: 'c760' },
  { name: f('p153_t13066', '加纳'), areaCode: 233, value: 'c770' },
  { name: f('p153_t13067', '希腊'), areaCode: 30, value: 'c780' },
  { name: f('p153_t13068', '格林纳达'), areaCode: 1809, value: 'c790' },
  { name: f('p153_t13069', '危地马拉'), areaCode: 502, value: 'c800' },
  { name: f('p153_t13070', '几内亚'), areaCode: 224, value: 'c810' },
  { name: f('p153_t13071', '几内亚比绍'), areaCode: 245, value: 'c820' },
  { name: f('p153_t13072', '圭亚那'), areaCode: 592, value: 'c830' },
  { name: f('p153_t13073', '海地'), areaCode: 509, value: 'c840' },
  { name: f('p153_t13074', '洪都拉斯'), areaCode: 504, value: 'c850' },
  { name: f('p153_t13075', '匈牙利'), areaCode: 36, value: 'c860' },
  { name: f('p153_t13076', '冰岛'), areaCode: 354, value: 'c870' },
  { name: f('p153_t13077', '印度'), areaCode: 91, value: 'c880' },
  { name: f('p153_t13078', '印尼'), areaCode: 62, value: 'c890' },
  { name: f('p153_t13079', '伊朗'), areaCode: 98, value: 'c900' },
  { name: f('p153_t13080', '伊拉克'), areaCode: 964, value: 'c910' },
  { name: f('p153_t13081', '爱尔兰'), areaCode: 353, value: 'c920' },
  { name: f('p153_t13082', '以色列'), areaCode: 972, value: 'c930' },
  { name: f('p153_t13083', '意大利'), areaCode: 39, value: 'c940' },
  { name: f('p153_t13084', '牙买加'), areaCode: 1876, value: 'c950' },
  // { name: f('p153_t13085', '日本'), areaCode: 81, value:'c960'},
  { name: f('p153_t13086', '约旦'), areaCode: 962, value: 'c970' },
  { name: f('p153_t13087', '哈萨克斯坦'), areaCode: 327, value: 'c980' },
  { name: f('p153_t13088', '肯尼亚'), areaCode: 254, value: 'c990' },
  { name: f('p153_t13089', '基里巴斯'), areaCode: 686, value: 'c1000' },
  { name: f('p153_t13090', '朝鲜'), areaCode: 850, value: 'c1010' },
  { name: f('p153_t13091', '韩国'), areaCode: 82, value: 'c1020' },
  { name: f('p153_t13092', '科索沃'), areaCode: 381, value: 'c1030' },
  { name: f('p153_t13093', '科威特'), areaCode: 965, value: 'c1040' },
  { name: f('p153_t13094', '吉尔吉斯斯坦'), areaCode: 996, value: 'c1050' },
  { name: f('p153_t13095', '老挝'), areaCode: 856, value: 'c1060' },
  { name: f('p153_t13096', '拉脱维亚'), areaCode: 371, value: 'c1070' },
  { name: f('p153_t13097', '黎巴嫩'), areaCode: 961, value: 'c1080' },
  { name: f('p153_t13098', '莱索托'), areaCode: 266, value: 'c1090' },
  { name: f('p153_t13099', '利比里亚'), areaCode: 231, value: 'c1100' },
  { name: f('p153_t13100', '利比亚'), areaCode: 218, value: 'c1110' },
  { name: f('p153_t13101', '列支敦士登'), areaCode: 423, value: 'c1120' },
  { name: f('p153_t13102', '立陶宛'), areaCode: 370, value: 'c1130' },
  { name: f('p153_t13103', '卢森堡'), areaCode: 352, value: 'c1140' },
  { name: f('p153_t13104', '马达加斯加'), areaCode: 261, value: 'c1150' },
  { name: f('p153_t13105', '马拉维'), areaCode: 265, value: 'c1160' },
  { name: f('p153_t13106', '马来西亚'), areaCode: 60, value: 'c1170' },
  { name: f('p153_t13107', '马尔代夫'), areaCode: 960, value: 'c1180' },
  { name: f('p153_t13108', '马里'), areaCode: 223, value: 'c1190' },
  { name: f('p153_t13109', '马耳他'), areaCode: 356, value: 'c1200' },
  { name: f('p153_t13110', '马绍尔群岛'), areaCode: 692, value: 'c1210' },
  { name: f('p153_t13111', '毛里塔尼亚'), areaCode: 222, value: 'c1220' },
  { name: f('p153_t13112', '毛里求斯'), areaCode: 230, value: 'c1230' },
  { name: f('p153_t13113', '墨西哥'), areaCode: 52, value: 'c1240' },
  { name: f('p153_t13114', '密克罗尼西亚'), areaCode: 691, value: 'c1250' },
  { name: f('p153_t13115', '摩尔多瓦'), areaCode: 373, value: 'c1260' },
  { name: f('p153_t13116', '摩纳哥'), areaCode: 377, value: 'c1270' },
  { name: f('p153_t13117', '蒙古'), areaCode: 976, value: 'c1280' },
  { name: f('p153_t13118', '黑山'), areaCode: 382, value: 'c1290' },
  { name: f('p153_t13119', '摩洛哥'), areaCode: 212, value: 'c1300' },
  { name: f('p153_t13120', '莫桑比克'), areaCode: 258, value: 'c1310' },
  { name: f('p153_t13121', '缅甸'), areaCode: 95, value: 'c1320' },
  { name: f('p153_t13122', '纳米比亚'), areaCode: 264, value: 'c1330' },
  { name: f('p153_t13123', '瑙鲁'), areaCode: 674, value: 'c1340' },
  { name: f('p153_t13124', '尼泊尔'), areaCode: 977, value: 'c1350' },
  { name: f('p153_t13125', '荷兰'), areaCode: 31, value: 'c1360' },
  { name: f('p153_t13126', '新西兰'), areaCode: 64, value: 'c1370' },
  { name: f('p153_t13127', '尼加拉瓜'), areaCode: 505, value: 'c1380' },
  { name: f('p153_t13128', '尼日尔'), areaCode: 977, value: 'c1390' },
  { name: f('p153_t13129', '尼日利亚'), areaCode: 234, value: 'c1400' },
  { name: f('p153_t13130', '北马其顿'), areaCode: 389, value: 'c1410' },
  { name: f('p153_t13131', '挪威'), areaCode: 47, value: 'c1420' },
  { name: f('p153_t13132', '阿曼'), areaCode: 968, value: 'c1430' },
  { name: f('p153_t13133', '巴基斯坦'), areaCode: 92, value: 'c1440' },
  { name: f('p153_t13134', '帕劳'), areaCode: 680, value: 'c1450' },
  { name: f('p153_t13135', '巴勒斯坦'), areaCode: 970, value: 'c1460' },
  { name: f('p153_t13136', '巴拿马'), areaCode: 507, value: 'c1470' },
  { name: f('p153_t13137', '巴布亚新几内亚'), areaCode: 675, value: 'c1480' },
  { name: f('p153_t13138', '巴拉圭'), areaCode: 595, value: 'c1490' },
  { name: f('p153_t13139', '秘鲁'), areaCode: 51, value: 'c1500' },
  { name: f('p153_t13140', '菲律宾'), areaCode: 63, value: 'c1510' },
  { name: f('p153_t13141', '波兰'), areaCode: 48, value: 'c1520' },
  { name: f('p153_t13142', '葡萄牙'), areaCode: 351, value: 'c1530' },
  { name: f('p153_t13143', '卡塔尔'), areaCode: 974, value: 'c1540' },
  { name: f('p153_t13144', '罗马尼亚'), areaCode: 40, value: 'c1550' },
  { name: f('p153_t13145', '俄罗斯'), areaCode: 7, value: 'c1560' },
  { name: f('p153_t13146', '卢旺达'), areaCode: 250, value: 'c1570' },
  { name: f('p153_t13147', '圣基茨和尼维斯'), areaCode: 1758, value: 'c1580' },
  { name: f('p153_t13148', '圣卢西亚'), areaCode: 1758, value: 'c1590' },
  { name: f('p153_t13149', '圣文森特和格林纳丁斯'), areaCode: 1784, value: 'c1600' },
  { name: f('p153_t13150', '萨摩亚'), areaCode: 685, value: 'c1610' },
  { name: f('p153_t13151', '圣马力诺'), areaCode: 378, value: 'c1620' },
  { name: f('p153_t13152', '圣多美和普林西比'), areaCode: 239, value: 'c1630' },
  { name: f('p153_t13153', '沙特阿拉伯'), areaCode: 966, value: 'c1640' },
  { name: f('p153_t13154', '塞内加尔'), areaCode: 221, value: 'c1650' },
  { name: f('p153_t13155', '塞尔维亚'), areaCode: 381, value: 'c1660' },
  { name: f('p153_t13156', '塞舌尔'), areaCode: 248, value: 'c1670' },
  { name: f('p153_t13157', '塞拉利昂'), areaCode: 232, value: 'c1680' },
  { name: f('p153_t13158', '新加坡'), areaCode: 65, value: 'c1690' },
  { name: f('p153_t13159', '斯洛伐克'), areaCode: 421, value: 'c1700' },
  { name: f('p153_t13160', '斯洛文尼亚'), areaCode: 386, value: 'c1710' },
  { name: f('p153_t13161', '所罗门群岛'), areaCode: 677, value: 'c1720' },
  { name: f('p153_t13162', '索马里'), areaCode: 252, value: 'c1730' },
  { name: f('p153_t13163', '南非'), areaCode: 27, value: 'c1740' },
  { name: f('p153_t13164', '南苏丹'), areaCode: 211, value: 'c1750' },
  { name: f('p153_t13165', '西班牙'), areaCode: 34, value: 'c1760' },
  { name: f('p153_t13166', '斯里兰卡'), areaCode: 94, value: 'c1770' },
  { name: f('p153_t13167', '苏丹'), areaCode: 249, value: 'c1780' },
  { name: f('p153_t13168', '苏里南'), areaCode: 597, value: 'c1790' },
  { name: f('p153_t13169', '瑞典'), areaCode: 46, value: 'c1800' },
  { name: f('p153_t13170', '瑞士'), areaCode: 41, value: 'c1810' },
  { name: f('p153_t13171', '叙利亚'), areaCode: 963, value: 'c1820' },
  { name: f('p153_t13172', '中国台湾'), areaCode: 886, value: 'c1830' },
  { name: f('p153_t13173', '塔吉克斯坦'), areaCode: 992, value: 'c1840' },
  { name: f('p153_t13174', '坦桑尼亚'), areaCode: 255, value: 'c1850' },
  { name: f('p153_t13175', '泰国'), areaCode: 66, value: 'c1860' },
  { name: f('p153_t13176', '多哥'), areaCode: 228, value: 'c1870' },
  { name: f('p153_t13177', '汤加'), areaCode: 676, value: 'c1880' },
  { name: f('p153_t13178', '特立尼达和多巴哥'), areaCode: 1809, value: 'c1890' },
  { name: f('p153_t13179', '突尼斯'), areaCode: 216, value: 'c1900' },
  { name: f('p153_t13180', '土耳其'), areaCode: 90, value: 'c1910' },
  { name: f('p153_t13181', '土库曼斯坦'), areaCode: 993, value: 'c1920' },
  { name: f('p153_t13182', '图瓦卢'), areaCode: 688, value: 'c1930' },
  { name: f('p153_t13183', '乌干达'), areaCode: 256, value: 'c1940' },
  { name: f('p153_t13184', '乌克兰'), areaCode: 380, value: 'c1950' },
  { name: f('p153_t13185', '阿拉伯联合酋长国'), areaCode: 971, value: 'c1960' },
  { name: f('p153_t13186', '英国'), areaCode: 44, value: 'c1970' },
  { name: f('p153_t13187', '美国'), areaCode: 1, value: 'c1980' },
  { name: f('p153_t13188', '乌拉圭'), areaCode: 598, value: 'c1990' },
  { name: f('p153_t13189', '乌兹别克斯坦'), areaCode: 233, value: 'c2000' },
  { name: f('p153_t13190', '瓦努阿图'), areaCode: 678, value: 'c2010' },
  { name: f('p153_t13191', '梵蒂冈'), areaCode: 39, value: 'c2020' },
  { name: f('p153_t13192', '委内瑞拉'), areaCode: 58, value: 'c2030' },
  { name: f('p153_t13193', '越南'), areaCode: 84, value: 'c2040' },
  { name: f('p153_t13194', '也门'), areaCode: 967, value: 'c2050' },
  { name: f('p153_t13195', '赞比亚'), areaCode: 260, value: 'c2060' },
  { name: f('p153_t13196', '津巴布韦'), areaCode: 263, value: 'c2070' },



  // { name: f('p153_t13197', '科索沃'), areaCode: 381, value: '科索沃' },
  // { name: f('p153_t13198', '台湾'), areaCode:None ,value:'台湾'},
  // { name: f('p153_t510', '中国香港'), areaCode: 852, value: '中国香港' },
  // { name: f('p153_t520', '中国澳门'), areaCode: 853, value: '中国澳门' },
  // { name: f('p153_t530', '中国台湾'), areaCode: 886, value: '中国台湾' },
  // { name: f('p153_t13199', '巴勒斯坦'), areaCode: 970, value: '巴勒斯坦' },
]
// export const country = [
//   { name: f('p153_t490', '日本'), areaCode: '81' },
//   { name: f('p153_t500', '中国'), areaCode: '86' },
//   { name: f('p153_t510', '中国香港'), areaCode: '852' },
//   { name: f('p153_t520', '中国澳门'), areaCode: '853' },
//   { name: f('p153_t530', '中国台湾'), areaCode: '886' },
//   { name: f('p153_t540', '韩国'), areaCode: '82' },
//   { name: f('p153_t550', '美国'), areaCode: '1' },
//   { name: f('p153_t560', '阿富汗'), areaCode: '93' },
//   { name: f('p153_t570', '阿尔巴尼亚'), areaCode: '355' },
//   { name: f('p153_t580', '阿尔及利亚'), areaCode: '213' },
//   { name: f('p153_t590', '萨摩亚'), areaCode: '684' },
//   { name: f('p153_t600', '安道尔共和国'), areaCode: '376' },
//   { name: f('p153_t610', '安哥拉'), areaCode: '244' },
//   { name: f('p153_t620', '安圭拉岛'), areaCode: '1264' },
//   { name: f('p153_t630', '南极洲'), areaCode: '672' },
//   { name: f('p153_t640', '安提瓜和巴布达'), areaCode: '1268' },
//   { name: f('p153_t650', '阿根廷'), areaCode: '54' },
//   { name: f('p153_t660', '亚美尼亚'), areaCode: '374' },
//   { name: f('p153_t670', '阿鲁巴'), areaCode: '297' },
//   { name: f('p153_t680', '澳大利亚'), areaCode: '61' },
//   { name: f('p153_t690', '奥地利'), areaCode: '43' },
//   { name: f('p153_t700', '阿塞拜疆'), areaCode: '994' },
//   { name: f('p153_t710', '巴哈马'), areaCode: '1242' },
//   { name: f('p153_t720', '巴林'), areaCode: '973' },
//   { name: f('p153_t730', '孟加拉国'), areaCode: '880' },
//   { name: f('p153_t740', '巴巴多斯'), areaCode: '1246' },
//   { name: f('p153_t750', '白俄罗斯'), areaCode: '375' },
//   { name: f('p153_t760', '比利时'), areaCode: '32' },
//   { name: f('p153_t770', '伯利兹城'), areaCode: '501' },
//   { name: f('p153_t780', '贝宁'), areaCode: '229' },
//   { name: f('p153_t790', '百慕大'), areaCode: '1441' },
//   { name: f('p153_t800', '不丹'), areaCode: '975' },
//   { name: f('p153_t810', '玻利维亚'), areaCode: '591' },
//   { name: f('p153_t820', '波斯尼亚和黑塞哥维那'), areaCode: '387' },
//   { name: f('p153_t830', '博茨瓦纳'), areaCode: '267' },
//   { name: f('p153_t840', '巴西'), areaCode: '55' },
//   { name: f('p153_t850', '英属印度洋领地'), areaCode: '246' },
//   { name: f('p153_t860', '文莱达鲁萨兰国'), areaCode: '673' },
//   { name: f('p153_t870', '保加利亚'), areaCode: '359' },
//   { name: f('p153_t880', '布基纳法索'), areaCode: '226' },
//   { name: f('p153_t890', '布隆迪'), areaCode: '257' },
//   { name: f('p153_t900', '柬埔寨'), areaCode: '855' },
//   { name: f('p153_t910', '喀麦隆'), areaCode: '237' },
//   { name: f('p153_t920', '佛得角'), areaCode: '238' },
//   { name: f('p153_t930', '开曼群岛'), areaCode: '1345' },
//   { name: f('p153_t940', '中非共和国'), areaCode: '236' },
//   { name: f('p153_t950', '乍得'), areaCode: '235' },
//   { name: f('p153_t960', '智利'), areaCode: '56' },
//   { name: f('p153_t970', '圣延岛'), areaCode: '61' },
//   { name: f('p153_t980', '科科斯群岛'), areaCode: '61' },
//   { name: f('p153_t990', '哥伦比亚'), areaCode: '57' },
//   { name: f('p153_t1000', '科摩罗'), areaCode: '269' },
//   { name: f('p153_t1010', '刚果'), areaCode: '242' },
//   { name: f('p153_t1020', '刚果民主共和国'), areaCode: '243' },
//   { name: f('p153_t1030', '库克群岛'), areaCode: '682' },
//   { name: f('p153_t1040', '哥斯达黎加'), areaCode: '506' },
//   { name: f('p153_t1050', '科特迪瓦'), areaCode: '225' },
//   { name: f('p153_t1060', '克罗地亚'), areaCode: '385' },
//   { name: f('p153_t1070', '古巴'), areaCode: '53' },
//   { name: f('p153_t1080', '塞浦路斯'), areaCode: '357' },
//   { name: f('p153_t1090', '捷克'), areaCode: '420' },
//   { name: f('p153_t1100', '丹麦'), areaCode: '45' },
//   { name: f('p153_t1110', '吉布提'), areaCode: '253' },
//   { name: f('p153_t1120', '多米尼克国'), areaCode: '1767' },
//   { name: f('p153_t1130', '多米尼加共和国'), areaCode: '1849' },
//   { name: f('p153_t1140', '东帝汶'), areaCode: '670' },
//   { name: f('p153_t1150', '厄瓜多尔'), areaCode: '593' },
//   { name: f('p153_t1160', '埃及'), areaCode: '20' },
//   { name: f('p153_t1170', '萨尔瓦多'), areaCode: '503' },
//   { name: f('p153_t1180', '赤道几内亚'), areaCode: '240' },
//   { name: f('p153_t1190', '爱沙尼亚'), areaCode: '372' },
//   { name: f('p153_t1200', '埃塞俄比亚'), areaCode: '251' },
//   { name: f('p153_t1210', '福克兰群岛'), areaCode: '500' },
//   { name: f('p153_t1220', '法罗群岛'), areaCode: '298' },
//   { name: f('p153_t1230', '斐济'), areaCode: '679' },
//   { name: f('p153_t1240', '芬兰'), areaCode: '358' },
//   { name: f('p153_t1250', '法国'), areaCode: '33' },
//   { name: f('p153_t1260', '法国大都会'), areaCode: '33' },
//   { name: f('p153_t1270', '法属圭亚那'), areaCode: '594' },
//   { name: f('p153_t1280', '法属玻里尼西亚'), areaCode: '689' },
//   { name: f('p153_t1290', '加蓬'), areaCode: '241' },
//   { name: f('p153_t1300', '冈比亚'), areaCode: '220' },
//   { name: f('p153_t1310', '格鲁吉亚'), areaCode: '995' },
//   { name: f('p153_t1320', '德国'), areaCode: '49' },
//   { name: f('p153_t1330', '加纳'), areaCode: '233' },
//   { name: f('p153_t1340', '直布罗陀'), areaCode: '350' },
//   { name: f('p153_t1350', '希腊'), areaCode: '30' },
//   { name: f('p153_t1360', '格陵兰'), areaCode: '45' },
//   { name: f('p153_t1370', '格林纳达'), areaCode: '1473' },
//   { name: f('p153_t1380', '瓜德罗普岛'), areaCode: '590' },
//   { name: f('p153_t1390', '关岛'), areaCode: '1671' },
//   { name: f('p153_t1400', '危地马拉'), areaCode: '502' },
//   { name: f('p153_t1410', '几内亚'), areaCode: '224' },
//   { name: f('p153_t1420', '几内亚比绍'), areaCode: '245' },
//   { name: f('p153_t1430', '圭亚那'), areaCode: '592' },
//   { name: f('p153_t1440', '海地'), areaCode: '509' },
//   { name: f('p153_t1450', '洪都拉斯'), areaCode: '504' },
//   { name: f('p153_t1460', '匈牙利'), areaCode: '36' },
//   { name: f('p153_t1470', '冰岛'), areaCode: '354' },
//   { name: f('p153_t1480', '印度'), areaCode: '91' },
//   { name: f('p153_t1490', '印度尼西亚'), areaCode: '62' },
//   { name: f('p153_t1500', '伊朗'), areaCode: '98' },
//   { name: f('p153_t1510', '伊拉克'), areaCode: '964' },
//   { name: f('p153_t1520', '爱尔兰'), areaCode: '353' },
//   { name: f('p153_t1530', '以色列'), areaCode: '972' },
//   { name: f('p153_t1540', '意大利'), areaCode: '39' },
//   { name: f('p153_t1550', '牙买加'), areaCode: '1876' },
//   { name: f('p153_t1560', '约旦'), areaCode: '962' },
//   { name: f('p153_t1570', '哈萨克'), areaCode: '7' },
//   { name: f('p153_t1580', '肯尼亚'), areaCode: '254' },
//   { name: f('p153_t1590', '科威特'), areaCode: '965' },
//   { name: f('p153_t1600', '吉尔吉斯'), areaCode: '996' },
//   { name: f('p153_t1610', '拉脱维亚'), areaCode: '371' },
//   { name: f('p153_t1620', '黎巴嫩'), areaCode: '961' },
//   { name: f('p153_t1630', '莱索托'), areaCode: '266' },
//   { name: f('p153_t1640', '利比里亚'), areaCode: '231' },
//   { name: f('p153_t1650', '利比亚'), areaCode: '218' },
//   { name: f('p153_t1660', '列支敦士登'), areaCode: '423' },
//   { name: f('p153_t1670', '立陶宛'), areaCode: '370' },
//   { name: f('p153_t1680', '卢森堡'), areaCode: '352' },
//   { name: f('p153_t1690', '马达加斯加'), areaCode: '261' },
//   { name: f('p153_t1700', '马拉维'), areaCode: '265' },
//   { name: f('p153_t1710', '马来西亚'), areaCode: '60' },
//   { name: f('p153_t1720', '马尔代夫'), areaCode: '960' },
//   { name: f('p153_t1730', '马里'), areaCode: '223' },
//   { name: f('p153_t1740', '马尔他'), areaCode: '356' },
//   { name: f('p153_t1750', '马提尼克岛'), areaCode: '596' },
//   { name: f('p153_t1760', '毛里塔尼亚'), areaCode: '222' },
//   { name: f('p153_t1770', '毛里求斯'), areaCode: '230' },
//   { name: f('p153_t1780', '马约特'), areaCode: '262' },
//   { name: f('p153_t1790', '墨西哥'), areaCode: '52' },
//   { name: f('p153_t1800', '密克罗尼西亚'), areaCode: '691' },
//   { name: f('p153_t1810', '摩尔多瓦'), areaCode: '373' },
//   { name: f('p153_t1820', '摩纳哥'), areaCode: '377' },
//   { name: f('p153_t1830', '外蒙古'), areaCode: '976' },
//   { name: f('p153_t1840', '黑山共和国'), areaCode: '382' },
//   { name: f('p153_t1850', '蒙特塞拉特'), areaCode: '1664' },
//   { name: f('p153_t1860', '摩洛哥'), areaCode: '212' },
//   { name: f('p153_t1870', '莫桑比克'), areaCode: '258' },
//   { name: f('p153_t1880', '缅甸'), areaCode: '95' },
//   { name: f('p153_t1890', '那米比亚'), areaCode: '264' },
//   { name: f('p153_t1900', '瑙鲁'), areaCode: '674' },
//   { name: f('p153_t1910', '尼泊尔'), areaCode: '977' },
//   { name: f('p153_t1920', '荷兰'), areaCode: '31' },
//   { name: f('p153_t1930', '荷兰安的列斯群岛'), areaCode: '599' },
//   { name: f('p153_t1940', '新喀里多尼亚'), areaCode: '687' },
//   { name: f('p153_t1950', '新西兰'), areaCode: '64' },
//   { name: f('p153_t1960', '尼加拉瓜'), areaCode: '505' },
//   { name: f('p153_t1970', '尼日尔'), areaCode: '227' },
//   { name: f('p153_t1980', '尼日利亚'), areaCode: '234' },
//   { name: f('p153_t1990', '诺福克岛'), areaCode: '6723' },
//   { name: f('p153_t2000', '朝鲜'), areaCode: '850' },
//   { name: f('p153_t2010', '北马里亚纳群岛'), areaCode: '1670' },
//   { name: f('p153_t2020', '挪威'), areaCode: '47' },
//   { name: f('p153_t2030', '阿曼'), areaCode: '968' },
//   { name: f('p153_t2040', '巴基斯坦'), areaCode: '92' },
//   { name: f('p153_t2050', '帛琉'), areaCode: '680' },
//   { name: f('p153_t2060', '巴勒斯坦'), areaCode: '970' },
//   { name: f('p153_t2070', '巴拿马'), areaCode: '507' },
//   { name: f('p153_t2080', '巴布亚新几内亚'), areaCode: '675' },
//   { name: f('p153_t2090', '巴拉圭'), areaCode: '595' },
//   { name: f('p153_t2100', '秘鲁'), areaCode: '51' },
//   { name: f('p153_t2110', '菲律宾共和国'), areaCode: '63' },
//   { name: f('p153_t2120', '皮特凯恩岛'), areaCode: '64' },
//   { name: f('p153_t2130', '波兰'), areaCode: '48' },
//   { name: f('p153_t2140', '葡萄牙'), areaCode: '351' },
//   { name: f('p153_t2150', '波多黎各'), areaCode: '1787' },
//   { name: f('p153_t2160', '卡塔尔'), areaCode: '974' },
//   { name: f('p153_t2170', '留尼汪岛'), areaCode: '262' },
//   { name: f('p153_t2180', '罗马尼亚'), areaCode: '40' },
//   { name: f('p153_t2190', '俄罗斯联邦'), areaCode: '7' },
//   { name: f('p153_t2200', '卢旺达'), areaCode: '250' },
//   { name: f('p153_t2210', '美属萨摩亚'), areaCode: '685' },
//   { name: f('p153_t2220', '圣马力诺共和国'), areaCode: '378' },
//   { name: f('p153_t2230', '沙特阿拉伯'), areaCode: '966' },
//   { name: f('p153_t2240', '塞内加尔'), areaCode: '221' },
//   { name: f('p153_t2250', '塞尔维亚共和国'), areaCode: '381' },
//   { name: f('p153_t2260', '塞舌尔'), areaCode: '248' },
//   { name: f('p153_t2270', '塞拉利昂'), areaCode: '232' },
//   { name: f('p153_t2280', '新加坡'), areaCode: '65' },
//   { name: f('p153_t2290', '斯洛伐克'), areaCode: '421' },
//   { name: f('p153_t2300', '斯洛文尼亚'), areaCode: '386' },
//   { name: f('p153_t2310', '索罗门群岛'), areaCode: '677' },
//   { name: f('p153_t2320', '索马里'), areaCode: '252' },
//   { name: f('p153_t2330', '南非'), areaCode: '27' },
//   { name: f('p153_t2340', '西班牙'), areaCode: '34' },
//   { name: f('p153_t2350', '斯里兰卡'), areaCode: '94' },
//   { name: f('p153_t2360', '苏丹'), areaCode: '249' },
//   { name: f('p153_t2370', '苏里南'), areaCode: '597' },
//   { name: f('p153_t2380', '斯威士兰'), areaCode: '268' },
//   { name: f('p153_t2390', '瑞典'), areaCode: '46' },
//   { name: f('p153_t2400', '瑞士'), areaCode: '41' },
//   { name: f('p153_t2410', '叙利亚'), areaCode: '963' },
//   { name: f('p153_t2420', '塔吉克'), areaCode: '992' },
//   { name: f('p153_t2430', '坦桑尼亚'), areaCode: '255' },
//   { name: f('p153_t2440', '泰国'), areaCode: '66' },
//   { name: f('p153_t2450', '多哥'), areaCode: '228' },
//   { name: f('p153_t2460', '汤加'), areaCode: '676' },
//   { name: f('p153_t2470', '特立尼达和多巴哥'), areaCode: '1868' },
//   { name: f('p153_t2480', '突尼斯'), areaCode: '216' },
//   { name: f('p153_t2490', '土耳其'), areaCode: '90' },
//   { name: f('p153_t2500', '土库曼'), areaCode: '993' },
//   { name: f('p153_t2510', '土克斯及开科斯群岛'), areaCode: '1809' },
//   { name: f('p153_t2520', '乌干达'), areaCode: '256' },
//   { name: f('p153_t2530', '乌克兰'), areaCode: '380' },
//   { name: f('p153_t2540', '阿拉伯联合酋长国'), areaCode: '971' },
//   { name: f('p153_t2550', '英国'), areaCode: '44' },
//   { name: f('p153_t2560', '乌拉圭'), areaCode: '598' },
//   { name: f('p153_t2570', '乌兹别克斯坦'), areaCode: '998' },
//   { name: f('p153_t2580', '瓦努阿图'), areaCode: '678' },
//   { name: f('p153_t2590', '梵蒂冈'), areaCode: '39' },
//   { name: f('p153_t2600', '委内瑞拉'), areaCode: '58' },
//   { name: f('p153_t2610', '越南'), areaCode: '84' },
//   { name: f('p153_t2620', '维尔京群岛'), areaCode: '1284' },
//   // { name: f('p153_t2630', ''), areaCode: '1340' },
//   { name: f('p153_t2640', '西撒哈拉'), areaCode: '685' },
//   { name: f('p153_t2650', '也门'), areaCode: '967' },
//   { name: f('p153_t2660', '南斯拉夫'), areaCode: '381' },
//   { name: f('p153_t2670', '赞比亚'), areaCode: '260' },
//   { name: f('p153_t2680', '津巴布韦'), areaCode: '263' },
//   { name: f('p153_t2690', '阿布哈兹'), areaCode: '7' },
//   { name: f('p153_t2700', '南奥赛梯'), areaCode: '7' },
//   { name: f('p153_t2710', '泽西岛'), areaCode: '44' },
//   { name: f('p153_t2720', '老挝'), areaCode: '856' },
//   { name: f('p153_t2730', '马其顿'), areaCode: '389' },
//   { name: f('p153_t2740', '圣基茨和尼维斯'), areaCode: '1869' },
//   { name: f('p153_t2750', '圣卢西亚岛'), areaCode: '1758' },
//   { name: f('p153_t2760', '圣文森特和格林纳丁斯'), areaCode: '1784' },
//   { name: f('p153_t2770', '圣多美和普林西比'), areaCode: '239' },
//   { name: f('p153_t2780', '南苏丹共和国'), areaCode: '211' }
// ]

export const getCountryText = (val) => {
  // debugger
  const r = country.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return country.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}


export const getCountryTextWithString = (val) => {
  if (!val) {
    return ''
  }
  let res = []
  let vals=val.split(',')
  vals.forEach((val) => {
    let txt = getCountryText(val)
    if (txt) {
      res.push(txt)
    }
  })

  return res.join(', ') //此处多加1个空格，免得被认为是个完整单词，而影响换行
}


export const getCountryTexts = (vals) => {
  if (!vals) {
    return ''
  }
  let res = []

  vals.forEach((val) => {
    let txt = getCountryText(val)
    if (txt) {
      res.push(txt)
    }
  })

  return res.join(',')
}

// export const getCountrysText = (val) => {
//   let str = ''
//   val.split(',').forEach((x, i) => {
//     str +=
//       country.filter((y) => y.areaCode === x + '')[0].name +
//       (val.split(',').length === i + 1 ? '' : ',')
//   })
//   return str
// }
export const event = [
  // { name: f('p153_t2810', '橄榄球（美式）'), value: 's100' },
  // { name: f('p153_t2820', '射箭'), value: 's110' },
  // { name: f('p153_t2830', '田径'), value: 's120' },
  // { name: f('p153_t2840', '赛车/摩托车'), value: 's130' },
  // { name: f('p153_t2850', '羽毛球'), value: 's140' },
  // { name: f('p153_t2860', '棒/垒球'), value: 's150' },
  // { name: f('p153_t2870', '篮球'), value: 's160' },
  // { name: f('p153_t2880', '冬季两项'), value: 's170' },
  // { name: f('p153_t2890', '斯诺克/台球'), value: 's180' },
  // { name: f('p153_t2900', '雪车/钢架雪车'), value: 's190' },
  // { name: f('p153_t2910', '拳击'), value: 's200' },
  // { name: f('p153_t2920', '皮划艇'), value: 's210' },
  // { name: f('p153_t2930', '登山'), value: 's220' },
  // { name: f('p153_t2940', '板球'), value: 's230' },
  // { name: f('p153_t2950', '冰壶'), value: 's240' },
  // { name: f('p153_t2960', '小人车'), value: 's250' },
  // { name: f('p153_t2970', '自行车'), value: 's260' },
  // { name: f('p153_t2980', '霹雳舞'), value: 's270' },
  // { name: f('p153_t2990', '飞镖'), value: 's280' },
  // { name: f('p153_t3000', '马术'), value: 's290' },
  // { name: f('p153_t3010', '击剑'), value: 's300' },
  // { name: f('p153_t3020', '飞盘'), value: 's310' },
  // { name: f('p153_t3030', '足球'), value: 's320' },
  // { name: f('p153_t3040', '高尔夫'), value: 's330' },
  // { name: f('p153_t3050', '体操'), value: 's340' },
  // { name: f('p153_t3060', '手球'), value: 's350' },
  // { name: f('p153_t3070', '曲棍球'), value: 's360' },
  // { name: f('p153_t3080', '柔道'), value: 's370' },
  // { name: f('p153_t3090', '空手道'), value: 's380' },
  // { name: f('p153_t3100', '棍网球'), value: 's390' },
  // { name: f('p153_t3110', '雪橇'), value: 's400' },
  // { name: f('p153_t3120', '现代五项'), value: 's410' },
  // { name: f('p153_t3130', '智力运动（棋牌类）'), value: 's420' },
  // { name: f('p153_t3140', '定向运动'), value: 's430' },
  // { name: f('p153_t3150', '马球'), value: 's440' },
  // { name: f('p153_t3160', '滑板'), value: 's450' },
  // { name: f('p153_t3170', '赛艇'), value: 's460' },
  // { name: f('p153_t3180', '橄榄球（英式）'), value: 's470' },
  // { name: f('p153_t3190', '帆船/帆板'), value: 's480' },
  // { name: f('p153_t3200', '射击'), value: 's490' },
  // { name: f('p153_t3210', '滑冰'), value: 's500' },
  // { name: f('p153_t3220', '滑雪'), value: 's510' },
  // { name: f('p153_t3230', '运动攀岩'), value: 's520' },
  // { name: f('p153_t3240', '相扑'), value: 's530' },
  // { name: f('p153_t3250', '冲浪'), value: 's540' },
  // { name: f('p153_t3260', '游泳'), value: 's550' },
  // { name: f('p153_t3270', '跳水'), value: 's560' },
  // { name: f('p153_t3280', '乒乓球'), value: 's570' },
  // { name: f('p153_t3290', '跆拳道'), value: 's580' },
  // { name: f('p153_t3300', '网球'), value: 's590' },
  // { name: f('p153_t3310', '铁人三项'), value: 's600' },
  // { name: f('p153_t3320', '排球'), value: 's610' },
  // { name: f('p153_t3330', '举重'), value: 's620' },
  // { name: f('p153_t3340', '摔跤'), value: 's630' },
  // { name: f('p153_t3350', '冰球'), value: 's640' },
  // { name: f('p153_t3360', '电子竞技'), value: 's650' },
  // { name: f('p153_t3370', '极限运动'), value: 's660' },
  // { name: f('p153_t3380', '障碍跑'), value: 's670' },
  // { name: f('p153_t3390', '综合格斗'), value: 's680' },
  // { name: f('p153_t3400', '其他'), value: 's690' },



  { name: f('p153_t2810', '足球'), value: 's100' },
  { name: f('p153_t2820', '篮球'), value: 's110' },
  { name: f('p153_t2830', '田径'), value: 's120' },
  { name: f('p153_t2840', '游泳'), value: 's130' },
  { name: f('p153_t2850', '网球'), value: 's140' },
  { name: f('p153_t2860', '乒乓球'), value: 's150' },
  { name: f('p153_t2870', '排球'), value: 's160' },
  { name: f('p153_t2880', '橄榄球'), value: 's170' },
  { name: f('p153_t2890', '美式橄榄球'), value: 's180' },
  { name: f('p153_t2900', '棒球'), value: 's190' },
  { name: f('p153_t2910', '垒球'), value: 's200' },
  { name: f('p153_t2920', '板球'), value: 's210' },
  { name: f('p153_t2930', '高尔夫'), value: 's220' },
  { name: f('p153_t2940', '滑冰'), value: 's230' },
  { name: f('p153_t2950', '体操'), value: 's240' },
  { name: f('p153_t2960', '自行车赛'), value: 's250' },
  { name: f('p153_t2970', '摩托车赛'), value: 's260' },
  { name: f('p153_t2980', 'F1赛车'), value: 's270' },
  { name: f('p153_t2990', '拳击'), value: 's280' },
  { name: f('p153_t3000', '柔道'), value: 's290' },
  { name: f('p153_t3010', '跆拳道'), value: 's300' },
  { name: f('p153_t3020', '摔跤'), value: 's310' },
  { name: f('p153_t3030', '举重'), value: 's320' },
  { name: f('p153_t3040', '射箭'), value: 's330' },
  { name: f('p153_t3050', '射击'), value: 's340' },
  { name: f('p153_t3060', '帆船'), value: 's350' },
  { name: f('p153_t3070', '划船'), value: 's360' },
  { name: f('p153_t3080', '皮划艇'), value: 's370' },
  { name: f('p153_t3090', '冲浪'), value: 's380' },
  { name: f('p153_t3100', '滑雪'), value: 's390' },
  { name: f('p153_t3110', '雪板'), value: 's400' },
  { name: f('p153_t3120', '冰球'), value: 's410' },
  { name: f('p153_t3130', '橄榄球联盟'), value: 's420' },
  { name: f('p153_t3140', '橄榄球联合会'), value: 's430' },
  { name: f('p153_t3150', '澳式足球'), value: 's440' },
  { name: f('p153_t3160', '羽毛球'), value: 's450' },
  { name: f('p153_t3170', '斯诺克/台球'), value: 's460' },
  { name: f('p153_t3180', '赛马'), value: 's470' },
  { name: f('p153_t3190', '赛车'), value: 's480' },
  { name: f('p153_t3200', '越野跑'), value: 's490' },
  { name: f('p153_t3210', '马拉松'), value: 's500' },
  { name: f('p153_t3220', '三项全能'), value: 's510' },
  { name: f('p153_t3230', '铁人三项'), value: 's520' },
  { name: f('p153_t3240', '骑马'), value: 's530' },
  { name: f('p153_t3250', '跳水'), value: 's540' },
  { name: f('p153_t3260', '水球'), value: 's550' },
  { name: f('p153_t3270', '空手道'), value: 's560' },
  { name: f('p153_t3280', '门球'), value: 's570' },
  { name: f('p153_t3290', '相扑'), value: 's580' },
  { name: f('p153_t3300', '高山滑雪'), value: 's590' },
  { name: f('p153_t3310', '电竞'), value: 's600' },
  { name: f('p153_t3320', '霹雳舞/街舞'), value: 's610' },
  { name: f('p153_t3330', '手球'), value: 's620' },
  { name: f('p153_t3340', '曲棍球'), value: 's630' },
  { name: f('p153_t3350', '冰壶'), value: 's640' },
  { name: f('p153_t3360', '沙滩排球'), value: 's650' },
  { name: f('p153_t3370', '沙滩足球'), value: 's660' },
  { name: f('p153_t3380', '智力体育'), value: 's670' },
  { name: f('p153_t3390', '越野滑雪'), value: 's680' },
  { name: f('p153_t3400', '潜水'), value: 's690' },
  { name: f('p153_t3401', '花样游泳'), value: 's700' },
  { name: f('p153_t3402', '马术'), value: 's710' },
  { name: f('p153_t3403', '龙舟'), value: 's720' },
  { name: f('p153_t3404', '水上摩托'), value: 's730' },
  { name: f('p153_t3405', '综合格斗/自由搏击'), value: 's740' },
  { name: f('p153_t3406', '沙滩手球'), value: 's750' },
  { name: f('p153_t3407', '极限运动'), value: 's760' },
  { name: f('p153_t3408', '残疾人运动'), value: 's770' },
  { name: f('p153_t3409', '其他'), value: 's900' },

]

export const getEventText = (val) => {
  const r = event.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return event.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}

export const getEventTexts = (vals) => {
  if (!vals) {
    return ''
  }
  let res = []

  vals.forEach((val) => {
    let txt = getEventText(val)
    if (txt) {
      res.push(txt)
    }
  })

  return res.join(',')
}
// export const getAssetsImge = (num) => {
//   let url = (Array(3).join('0') + num).slice(-3)
//   return new URL(`../assets/images/common/${url}.png`, import.meta.url).href
// }

export const interest = [
  // { name: f('p153_t3410', '音乐'), value: 1 },
  // { name: f('p153_t3420', '旅行与体验当地文化'), value: 2 },
  // { name: f('p153_t3430', '艺术与设计'), value: 3 },
  // { name: f('p153_t3440', '时尚与美妆'), value: 4 },
  // { name: f('p153_t3450', '年轻力与街头文化'), value: 5 },
  // { name: f('p153_t3460', '阅读书籍'), value: 6 },
  // { name: f('p153_t3470', '游戏与科技'), value: 7 },
  // { name: f('p153_t3480', '营养与健康'), value: 8 },
  // { name: f('p153_t3490', '户外生活'), value: 9 },
  // { name: f('p153_t3500', '宠物爱好者'), value: 10 },
  // { name: f('p153_t3510', '性别平等和种族平等'), value: 11 },
  // { name: f('p153_t3520', '妇女赋权'), value: 12 },
  // { name: f('p153_t3530', '社会责任与社区关爱'), value: 13 },
  // { name: f('p153_t3540', '环保与可持续发展'), value: 14 },
  // { name: f('p153_t3550', '青年文化'), value: 15 },

  { name: f('p153_t5000', '音乐'), value: 50 },
  { name: f('p153_t5010', '旅游'), value: 51 },
  { name: f('p153_t5020', '艺术'), value: 52 },
  { name: f('p153_t5030', '时尚'), value: 53 },
  { name: f('p153_t5040', '书籍'), value: 54 },
  { name: f('p153_t5050', '游戏'), value: 55 },
  { name: f('p153_t5060', '科技/互联网'), value: 56 },
  { name: f('p153_t5070', '健康/美容'), value: 57 },
  { name: f('p153_t5080', '户外活动'), value: 58 },
  { name: f('p153_t5090', '宠物/动物'), value: 59 },
  { name: f('p153_t5100', '电影/剧院'), value: 60 },
  { name: f('p153_t5110', '烹饪'), value: 61 },
  { name: f('p153_t5120', '餐饮'), value: 62 },
  { name: f('p153_t5130', '饮酒'), value: 63 },
  { name: f('p153_t5140', '体育观赏'), value: 64 },
  { name: f('p153_t5150', '体育活动'), value: 65 },
  { name: f('p153_t5160', '舞蹈'), value: 66 },
  { name: f('p153_t5170', '瑜伽/普拉提'), value: 67 },
  { name: f('p153_t5180', '温泉/桑拿'), value: 68 },
  { name: f('p153_t5190', '摄影'), value: 69 },
  { name: f('p153_t5200', '钓鱼'), value: 70 },
  { name: f('p153_t5210', '自行车'), value: 71 },
  { name: f('p153_t5220', '汽车/摩托车'), value: 72 },
  { name: f('p153_t5230', '股票/投资'), value: 73 },
  { name: f('p153_t5240', '购物'), value: 74 },
  { name: f('p153_t5250', '园艺'), value: 75 },
  { name: f('p153_t5260', '电视/视频'), value: 76 },
  { name: f('p153_t5270', '语言'), value: 77 },
  { name: f('p153_t5280', '房地产'), value: 78 },
  { name: f('p153_t5290', '博彩/赌博'), value: 79 },
  { name: f('p153_t5300', '青年赋权'), value: 80 },
  { name: f('p153_t5310', '性别平等'), value: 81 },
  { name: f('p153_t5320', '反种族主义'), value: 82 },
  { name: f('p153_t5330', '社会贡献'), value: 83 },
  { name: f('p153_t5340', '环境可持续性'), value: 84 },

  { name: f('p153_t3560', '其他'), value: 100 }




]
export const getInterestText = (val) => {
  const r = interest.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return interest.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}

export const getInterestTexts = (vals) => {
  if (!vals) {
    return ''
  }
  let res = []

  vals.forEach((val) => {
    let txt = getInterestText(val)
    if (txt) {
      res.push(txt)
    }
  })

  return res.join(',')
}

// export const brandList = [
//   { name: f('p153_t3570', '音响设备'), value: 'b10' },
//   { name: f('p153_t3580', '自行车'), value: 'b20' },
//   { name: f('p153_t3590', '建筑材料'), value: 'b30' },
//   { name: f('p153_t3600', '摄影摄像器材'), value: 'b40' },
//   { name: f('p153_t3610', '儿童玩具'), value: 'b50' },
//   { name: f('p153_t3620', '清洁产品和服务'), value: 'b60' },
//   { name: f('p153_t3630', '云计算'), value: 'b70' },
//   { name: f('p153_t3640', '化妆品和服务'), value: 'b80' },
//   { name: f('p153_t3650', '电脑和配件'), value: 'b90' },
//   { name: f('p153_t3660', '旅游服务'), value: 'b100' },
//   { name: f('p153_t3670', '商用户外广告设备'), value: 'b110' },
//   { name: f('p153_t3680', '教育机构'), value: 'b120' },
//   { name: f('p153_t3690', '电动滑板车平衡车'), value: 'b130' },
//   { name: f('p153_t3700', '人力资源服务'), value: 'b140' },
//   { name: f('p153_t3710', '农用机械和服务'), value: 'b150' },
//   { name: f('p153_t3720', '国际汇兑'), value: 'b160' },
//   { name: f('p153_t3730', '男式正装'), value: 'b170' },
//   { name: f('p153_t3740', '家具和家居用品'), value: 'b180' },
//   { name: f('p153_t3750', '电子游戏和虚拟现实设备'), value: 'b190' },
//   { name: f('p153_t3760', '地面交通运输服务'), value: 'b200' },
//   { name: f('p153_t3770', '园艺'), value: 'b210' },
//   { name: f('p153_t3771', '地面维护产品和服务'), value: 'b211' },
//   { name: f('p153_t3780', '空调和暖气'), value: 'b220' },
//   { name: f('p153_t3790', '保健/个人护理产品和服务'), value: 'b230' },
//   { name: f('p153_t3800', '家装材料和服务'), value: 'b240' },
//   { name: f('p153_t3810', '医院和诊所'), value: 'b250' },
//   { name: f('p153_t3820', '化学工业'), value: 'b260' },
//   { name: f('p153_t3830', '工业机械设备'), value: 'b270' },
//   { name: f('p153_t3840', '保险'), value: 'b280' },
//   { name: f('p153_t3850', '厨房电器'), value: 'b290' },
//   { name: f('p153_t3860', '仓储物流服务'), value: 'b300' },
//   { name: f('p153_t3870', '润滑油'), value: 'b310' },
//   { name: f('p153_t3880', '管理咨询服务'), value: 'b320' },
//   { name: f('p153_t3890', '移动通信网络'), value: 'b330' },
//   { name: f('p153_t3900', '摩托车、燃油踏板车和其他两轮燃油车辆'), value: 'b340' },
//   { name: f('p153_t3910', '眼镜和光学仪器'), value: 'b350' },
//   { name: f('p153_t3920', '油漆涂料'), value: 'b360' },
//   { name: f('p153_t3930', '非处方药'), value: 'b370' },
//   { name: f('p153_t3940', '预包装冰淇淋'), value: 'b380' },
//   { name: f('p153_t3950', '预包装加工膳食产品（如冷冻膳食、冷冻披萨、未煮熟的面食）'), value: 'b390' },
//   { name: f('p153_t3960', '可再生能源'), value: 'b400' },
//   { name: f('p153_t3970', '零售商业银行'), value: 'b410' },
//   { name: f('p153_t3980', '咸味零食'), value: 'b420' },
//   { name: f('p153_t3990', '物业管理'), value: 'b430' },
//   { name: f('p153_t4000', '智能家居'), value: 'b440' },
//   { name: f('p153_t4010', '文具和办公用品'), value: 'b450' },
//   { name: f('p153_t4020', '固体矿产勘察、开采和生产'), value: 'b460' },
//   { name: f('p153_t4030', '白家电'), value: 'b470' },
//   { name: f('p153_t4040', '葡萄酒（除气泡酒）'), value: 'b480' },
//   { name: f('p153_t4050', '轮胎'), value: 'b490' },
//   { name: f('p153_t4060', '无线无线电通信设备（如:对讲机）'), value: 'b500' },
//   { name: f('p153_t4070', '航空公司和航空旅行'), value: 'b510' },
//   { name: f('p153_t4080', '任何种类的饮料（非起泡葡萄酒除外）'), value: 'b520' },
//   { name: f('p153_t4090', '轿车、面包车、长途客车和巴士制造商'), value: 'b530' },
//   { name: f('p153_t4100', '香槟和气泡酒'), value: 'b540' },
//   { name: f('p153_t4110', '电影院'), value: 'b550' },
//   { name: f('p153_t4120', '服装/服饰（包括所有运动装和鞋），"正式男性商务装  "除外'), value: 'b560' },
//   { name: f('p153_t4130', '糖果店、烟草店和报摊'), value: 'b570' },
//   { name: f('p153_t4140', '烟草'), value: 'b580' },
//   { name: f('p153_t4150', '报刊亭'), value: 'b590' },
//   { name: f('p153_t4160', '健身俱乐部、健身房、健康俱乐部、水疗中心和  "健康 "零售业务'), value: 'b600' },
//   { name: f('p153_t4170', '食品（以上适用名单包含的类别除外）'), value: 'b610' },
//   { name: f('p153_t4180', '量贩店、超市和大卖场'), value: 'b620' },
//   { name: f('p153_t4190', '移动通信终端'), value: 'b630' },
//   { name: f('p153_t4200', '支付服务和技术'), value: 'b640' },
//   { name: f('p153_t4210', '石油天然气和燃料'), value: 'b650' },
//   { name: f('p153_t4220', '药房'), value: 'b660' },
//   { name: f('p153_t4230', '房地产开发商'), value: 'b670' },
//   { name: f('p153_t4240', '餐厅（含快餐）'), value: 'b680' },
//   { name: f('p153_t4250', '商场'), value: 'b690' },
//   { name: f('p153_t4260', '体育装备和配件'), value: 'b700' },
//   { name: f('p153_t4270', '超市'), value: 'b710' },
//   { name: f('p153_t4280', '主题公园'), value: 'b720' },
//   { name: f('p153_t4290', '电视机和家用投影'), value: 'b730' },
//   { name: f('p153_t4300', '手表'), value: 'b740' },
//   { name: f('p153_t4310', '酒店'), value: 'b750' }
// ]

export const brandList = [
  { name: f(  'p153_tp5500', '音响设备'), value:'b10' },
  { name: f(  'p153_tp5510', '摄影摄像器材'), value:'b20' },
  { name: f(  'p153_tp5520', '电视机和家用投影'), value:'b30' },
  { name: f(  'p153_tp5530', '电子游戏和虚拟现实设备'), value:'b40' },
  { name: f(  'p153_tp5540', '电脑和配件'), value:'b50' },
  { name: f(  'p153_tp5550', '云计算'), value:'b60' },
  { name: f(  'p153_tp5560', '软件和IT服务'), value:'b70' },
  { name: f(  'p153_tp5570', '移动通信终端'), value:'b80' },
  { name: f(  'p153_tp5580', '移动通信网络'), value:'b90' },
  { name: f(  'p153_tp5590', '白家电，小家电'), value:'b100' },
  { name: f(  'p153_tp5600', '自行车'), value:'b110' },
  { name: f(  'p153_tp5610', '摩托车、燃油踏板车和其他两轮燃油车辆'), value:'b120' },
  { name: f(  'p153_tp5620', '电动滑板车平衡车'), value:'b130' },
  { name: f(  'p153_tp5630', '轿车、面包车、长途客车和巴士制造商'), value:'b140' },
  { name: f(  'p153_tp5640', '二手车销售服务'), value:'b150' },
  { name: f(  'p153_tp5650', '轮胎'), value:'b160' },
  { name: f(  'p153_tp5660', '润滑油'), value:'b170' },
  { name: f(  'p153_tp5670', '清洁产品和服务'), value:'b180' },
  { name: f(  'p153_tp5680', '化妆品和服务'), value:'b190' },
  { name: f(  'p153_tp5690', '保健/个人护理产品和服务'), value:'b200' },
  { name: f(  'p153_tp5700', '眼镜和光学仪器'), value:'b210' },
  { name: f(  'p153_tp5710', '文具和办公用品'), value:'b220' },
  { name: f(  'p153_tp5720', '儿童玩具'), value:'b230' },
  { name: f(  'p153_tp5730', '报社及新闻机构'), value:'b240' },
  { name: f(  'p153_tp5740', '教育机构'), value:'b250' },
  { name: f(  'p153_tp5750', '人力资源服务'), value:'b260' },
  { name: f(  'p153_tp5760', '管理咨询服务'), value:'b270' },
  { name: f(  'p153_tp5770', '旅游服务'), value:'b280' },
  { name: f(  'p153_tp5780', '航空公司和航空旅行'), value:'b290' },
  { name: f(  'p153_tp5790', '地面交通运输服务'), value:'b300' },
  { name: f(  'p153_tp5800', '仓储物流服务'), value:'b310' },
  { name: f(  'p153_tp5810', '园艺/地面维护产品和服务'), value:'b320' },
  { name: f(  'p153_tp5820', '物业管理'), value:'b330' },
  { name: f(  'p153_tp5830', '房地产开发商'), value:'b340' },
  { name: f(  'p153_tp5840', '建筑材料'), value:'b350' },
  { name: f(  'p153_tp5850', '家装材料和服务'), value:'b360' },
  { name: f(  'p153_tp5860', '家具和家居用品'), value:'b370' },
  { name: f(  'p153_tp5870', '油漆涂料'), value:'b380' },
  { name: f(  'p153_tp5880', '化学工业'), value:'b390' },
  { name: f(  'p153_tp5890', '工业机械设备'), value:'b400' },
  { name: f(  'p153_tp5900', '农用机械和服务'), value:'b410' },
  { name: f(  'p153_tp5910', '零售商业银行'), value:'b420' },
  { name: f(  'p153_tp5920', '保险'), value:'b430' },
  { name: f(  'p153_tp5930', '国际汇兑'), value:'b440' },
  { name: f(  'p153_tp5940', '支付服务和技术'), value:'b450' },
  { name: f(  'p153_tp5950', '预包装加工膳食产品（如冷冻膳食、冷冻披萨等）'), value:'b460' },
  { name: f(  'p153_tp5960', '零食'), value:'b470' },
  { name: f(  'p153_tp5970', '食品（以上适用名单包含的类别除外）'), value:'b480' },
  { name: f(  'p153_tp5980', '纯净水，矿泉水'), value:'b490' },
  { name: f(  'p153_tp5990', '软饮料（不包括功能饮料和运动饮料）'), value:'b500' },
  { name: f(  'p153_tp6000', '功能性饮料和运动饮料'), value:'b510' },
  { name: f(  'p153_tp6010', '牛奶和其他乳制品'), value:'b520' },
  { name: f(  'p153_tp6020', '啤酒'), value:'b530' },
  { name: f(  'p153_tp6030', '葡萄酒、香槟和气泡酒'), value:'b540' },
  { name: f(  'p153_tp6040', '烈性酒/蒸馏酒/日本清酒'), value:'b550' },
  { name: f(  'p153_tp6050', '男式商务装'), value:'b560' },
  { name: f(  'p153_tp6060', '服装/服饰（男性商务装和运动服饰除外)'), value:'b570' },
  { name: f(  'p153_tp6070', '运动服饰和运动鞋'), value:'b580' },
  { name: f(  'p153_tp6080', '体育装备和配件'), value:'b590' },
  { name: f(  'p153_tp6090', '手表'), value:'b600' },
  { name: f(  'p153_tp6100', '可再生能源'), value:'b610' },
  { name: f(  'p153_tp6110', '石油天然气和燃料'), value:'b620' },
  { name: f(  'p153_tp6120', '固体矿产勘察、开采和生产'), value:'b630' },
  { name: f(  'p153_tp6130', '商场'), value:'b640' },
  { name: f(  'p153_tp6140', '量贩店、超市和大卖场'), value:'b650' },
  { name: f(  'p153_tp6150', '杂货店，便利店'), value:'b660' },
  { name: f(  'p153_tp6160', '电商平台'), value:'b670' },
  { name: f(  'p153_tp6170', '餐厅（含快餐）'), value:'b680' },
  { name: f(  'p153_tp6180', '酒店'), value:'b690' },
  { name: f(  'p153_tp6190', '电影院'), value:'b700' },
  { name: f(  'p153_tp6200', '主题公园'), value:'b710' },
  { name: f(  'p153_tp6210', '健身房、健康俱乐部等健康服务'), value:'b720' },
  { name: f(  'p153_tp6220', '非处方药'), value:'b730' },
  { name: f(  'p153_tp6230', '医院和诊所'), value:'b740' },
  { name: f(  'p153_tp6240', '体育博彩'), value:'b750' },
  { name: f(  'p153_tp6250', '烟草'), value:'b760' },
  { name: f(  'p153_tp6260', '药房'), value:'b770' },
  { name: f(  'p153_tp6270', '虚拟货币，NFT'), value:'b780' },
  { name: f(  'p153_tp6280', '其他'), value:'b790' },
]

export const getBrandText = (val) => {
  const r = brandList.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return brandList.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}


export const forbiddenIndustry = [
  { name: f('p105_t1230', '博彩业'), value: 'f10' },
  { name: f('p105_t1250', '烟草'), value: 'f20' },
  { name: f('p105_t1270', '非处方'), value: 'f30' },
  { name: f('p105_t1290', '烈性酒'), value: 'f40' },
  { name: f('p105_t1310', '其他酒类'), value: 'f50' },
  { name: f('p105_t1330', '金融理财'), value: 'f60' },
  { name: f('p105_t1350', '小额贷'), value: 'f70' },
  { name: f('p105_t1370', '区块链及虚拟货币'), value: 'f80' }
]


export const getForbiddenIndustryText = (val) => {
  const r = forbiddenIndustry.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return forbiddenIndustry.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}

export const achiveLevel = [
  { name: f('p105_t510', '国家级'), value: 'a10' },
  { name: f('p105_t530', '洲际'), value: 'a20' },
  { name: f('p105_t550', '世界级'), value: 'a30' },
]

export const getAchiveLevelText = (val) => {
  const r = achiveLevel.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return achiveLevel.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}

export const achiveType = [
  { name: f('p105_t580', '第一名'), value: 't10' },
  { name: f('p105_t600', '第二名'), value: 't20' },
  { name: f('p105_t620', '第三名'), value: 't30' },
  { name: f('p105_t640', '其他'), value: 't40' },
]

export const getAchiveTypeText = (val) => {
  const r = achiveType.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return achiveType.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}



export const personStatus = [
  { name: f('p105_t2100', '现役运动员'), value: 's10' },
  { name: f('p105_t2101', '退役'), value: 's20' },
  { name: f('p105_t2103', '其他'), value: 's90' },
]

export const getPersonStatusText = (val) => {
  const r = personStatus.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return personStatus.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}


export const professionLevel = [
  { name: f('p105_t1050', '一级'), value: 's10' },
  { name: f('p105_t1070', '二级'), value: 't20' },
  { name: f('p105_t1090', '三级'), value: 's20' },
  { name: f('p105_t1110', '其他'), value: 's40' },
]

export const getProfessionLevelText = (val) => {
  const r = professionLevel.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return professionLevel.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}


export const bochuType = [
  { name: f('p105_t800', '电视直播'), value: 'b10' },
  { name: f('p105_t820', '网络直播'), value: 'b20' },
  { name: f('p105_t840', '其他形式播出'), value: 'b90' },
]

export const getBochuTypeText = (val) => {
  const r = bochuType.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return bochuType.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}

export const getBochuTypeTexts = (vals) => {
  if (!vals) {
    return ''
  }
  let res = []

  vals.forEach((val) => {
    let txt = getBochuTypeText(val)
    if (txt) {
      res.push(txt)
    }
  })

  return res.join(',')
}


export const currencyType = [
  { name: f('p105_t2000', '日元'), value: 'c101', icon: 'jp', code: 'JPY' },
  { name: f('p105_t2050', '美元'), value: 'c106', icon: 'us', code: 'USD' },
  { name: f('p105_t2080', '欧元'), value: 'c109', icon: 'eu', code: 'EUR' },
  { name: f('p105_t2030', '瑞士法郎'), value: 'c104', icon: 'ch', code: 'CHF' },
  { name: f('p105_t2070', '英镑'), value: 'c108', icon: 'gb', code: 'GBP' },
  { name: f('p105_t2090', '加币'), value: 'c110', icon: 'ca', code: 'CAD' },
  { name: f('p105_t2060', '澳元'), value: 'c107', icon: 'au', code: 'AUD' },
  { name: f('p105_t2040', '新加坡元'), value: 'c105', icon: 'sg', code: 'SGD' },
  { name: f('p105_t2010', '人民币'), value: 'c102', icon: 'cn', code: 'CNY' },
  { name: f('p105_t2020', '港币'), value: 'c103', icon: 'hk', code: 'HKD' },
]

export const getCurrencyTypeText = (val) => {
  // console.log(val)

  const r = currencyType.filter((item) => item.value === val)
  // console.log(r)
  if (r && r.length > 0) {
    return currencyType.filter((item) => item.value === val)[0].code
  } else {
    return val
  }
}


export const getMoneyText = (b1, b2, currency) => {
  if (b1 == b2) {
    return formatQianfenwei(b1) + ' ' + getCurrencyTypeText(currency)
  }
  return formatQianfenwei(b1) + '~' + formatQianfenwei(b2) + ' ' + getCurrencyTypeText(currency)
}

export const zanzhuLevelType = [
  { name: f('p107_t550', '冠名赞助商'), value: 'a10' },
  { name: f('p107_t560', '战略合作伙伴'), value: 'a20' },
  { name: f('p107_t570', '官方赞助商'), value: 'a30', },
  { name: f('p107_t580', '官方供应商'), value: 'a40', },
  { name: f('p107_t590', '其他'), value: 'a90', },

]

export const getZanzhuLevelText = (val) => {
  const r = zanzhuLevelType.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return zanzhuLevelType.filter((item) => item.value === val)[0].name
  } else {
    return ''
  }
}

export const personZanzhuLevelType = [
  { name: f('p107_t600', '品牌代言'), value: 's10' },
  { name: f('p107_t610', '社交媒体'), value: 's20' },
  { name: f('p107_t620', '出席活动'), value: 's30', },
  { name: f('p107_t630', '参与平面及视频拍摄'), value: 's40', },
  { name: f('p107_t640', '肖像授权'), value: 's50', },
  { name: f('p107_t650', '直播带货'), value: 's60', },
  // { name: f('p107_t660', '实物赞助'), value: 's70', },
]

export const getPersonZanzhuLevelTypeText = (val) => {
  const r = personZanzhuLevelType.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return r[0].name
  } else {
    return ''
  }
}


export const articleCategoryType = [
  { name: f('p154_t1010', '采访'), value: '10' },
  { name: f('p154_t1020', '文章'), value: '20' },
  
]

export const getArticleCategoryTypeText = (val) => {
  const r = articleCategoryType.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return r[0].name
  } else {
    return ''
  }
}



export const articleTagType = [
  { name: f('p154_t1090', '采访'), value: '10' },
  { name: f('p154_t1091', '新闻'), value: '20' },
  { name: f('p154_t1092', '观点'), value: '30' },
  { name: f('p154_t1099', '其他'), value: '90' },
]



export const getArticleTagText = (val) => {
  const r = articleTagType.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return r[0].name
  } else {
    return ''
  }
}






export const confirmSendMessageType = [
  { name: f('p107_t720', '您好，我们是【%1】行业的品牌，我对与您发布的【%2】资源合作感兴趣，是否可以沟通一下？'), value: '100' },
]

export const getConfirmSendMessageTypeText = (val) => {
  const r = confirmSendMessageType.filter((item) => item.value === val)
  if (r && r.length > 0) {
    return r[0].name
  } else {
    return ''
  }
}


//el-upload选择图片
export const chooseImg = (uploadFile, uploadRef, cropperObj) => {
  if (
    ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].indexOf(uploadFile.raw.type) ===
    -1
  ) {
    ElMessage({
      type: 'warning',
      message: 'logo格式必须为png，jpg，jpeg，gif',
    })
    uploadRef.value?.clearFiles()
    return
  } else if (uploadFile.raw.size / 1024 / 1024 > 5) {
    ElMessage({
      type: 'warning',
      message: f('p126_t270', '上传大小不能超过5Mb'),
    })
    uploadRef.value?.clearFiles()
    return
  } else if (uploadFile.status !== 'ready' || !uploadFile.raw) {
    uploadRef.value?.clearFiles()
    return
  }
  const reader = new FileReader()
  reader.readAsDataURL(uploadFile.raw)
  reader.onload = () => {
    // 当数据存在赋值给截图弹窗
    if (reader.result) {
      // console.log(reader.result)
      if (reader.result && typeof reader.result === 'object') {
        // 把Array Buffer转化为blob 如果是base64不需要
        cropperObj.value.img = window.URL.createObjectURL(new Blob([reader.result]))
      } else {
        cropperObj.value.img = reader.result
      }
      cropperObj.value.fileName = uploadFile.raw.name || ''
      cropperObj.value.isShow = true
      uploadRef.value?.clearFiles()
    }
  }
}


// export const chooseResourceImg = (
//   uploadFile,
//   uploadRef,
//   cropperObj,
//   package_index,
//   activity_index
// ) => {
//   if (
//     ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].indexOf(
//       uploadFile.raw.type
//     ) === -1
//   ) {
//     ElMessage({
//       type: 'warning',
//       message: 'logo格式必须为png，jpg，jpeg，gif'
//     })
//     uploadRef.value?.clearFiles()
//     return
//   } else if (uploadFile.raw.size / 1024 / 1024 > 5) {
//     ElMessage({
//       type: 'warning',
//       message: f('p126_t270', '上传大小不能超过5Mb')
//     })
//     uploadRef.value?.clearFiles()
//     return
//   } else if (uploadFile.status !== 'ready' || !uploadFile.raw) {
//     uploadRef.value?.clearFiles()
//     return
//   }
//   const reader = new FileReader()
//   reader.readAsDataURL(uploadFile.raw)
//   reader.onload = () => {
//     // 当数据存在赋值给截图弹窗
//     if (reader.result) {
//       console.log(reader.result)
//       if (reader.result && typeof reader.result === 'object') {
//         // 把Array Buffer转化为blob 如果是base64不需要
//         cropperObj.value.img = window.URL.createObjectURL(
//           new Blob([reader.result])
//         )
//       } else {
//         cropperObj.value.img = reader.result
//       }
//       cropperObj.value.fileName = uploadFile.raw.name || ''
//       cropperObj.value.isShow = true
//       cropperObj.value.packageIndex = package_index // 添加 package_index
//       cropperObj.value.activityIndex = activity_index // 添加 activity_index
//       uploadRef.value?.clearFiles()
//     }
//   }
// }


export const getSexText = (val) => {
  // console.log(val)
  // console.log(typeof val)
  if (val === '1') {
    return f('p105_t200', '男')
  }
  else if (val === '2') {
    return f('p105_t210', '女')
  }
  return '-'
}

export const combineYMD = (y, m, d) => {
  let tmp = ''
  if (y) { tmp += y }
  if (m) { tmp += '-' + m }
  if (d) { tmp += '-' + d }

  if(!tmp) {
    tmp=f('p105_t211', '无')
  }

  return tmp
}


export const langs = [
  {
    name: '日本語',
    key: 'jp',
  },
  {
    name: 'English',
    key: 'en',
  },
  {
    name: '简体中文',
    key: 'zh-cn',
  },
]


export const allContinent = [
  {
    value: 'z0',
    label: f('p153_t7009', '全球'),
  },
  {
    value: 'z1',
    label: f('p153_t7010', '欧洲'),
    children: [
      { value: 'g1010', label: f('p153_t7020', '芬兰') },
      { value: 'g1020', label: f('p153_t7030', '瑞典') },
      { value: 'g1030', label: f('p153_t7040', '挪威') },
      { value: 'g1040', label: f('p153_t7050', '冰岛') },
      { value: 'g1050', label: f('p153_t7060', '丹麦') },
      { value: 'g1060', label: f('p153_t7070', '爱沙尼亚') },
      { value: 'g1070', label: f('p153_t7080', '拉脱维亚') },
      { value: 'g1080', label: f('p153_t7090', '立陶宛') },
      { value: 'g1090', label: f('p153_t7100', '白俄罗斯') },
      { value: 'g1100', label: f('p153_t7110', '俄罗斯') },
      { value: 'g1110', label: f('p153_t7120', '乌克兰') },
      { value: 'g1120', label: f('p153_t7130', '摩尔多瓦') },
      { value: 'g1130', label: f('p153_t7140', '波兰') },
      { value: 'g1140', label: f('p153_t7150', '捷克') },
      { value: 'g1150', label: f('p153_t7160', '斯洛伐克') },
      { value: 'g1160', label: f('p153_t7170', '匈牙利') },
      { value: 'g1170', label: f('p153_t7180', '德国') },
      { value: 'g1180', label: f('p153_t7190', '奥地利') },
      { value: 'g1190', label: f('p153_t7200', '瑞士') },
      { value: 'g1200', label: f('p153_t7210', '列支敦士登') },
      { value: 'g1210', label: f('p153_t7220', '英国') },
      { value: 'g1220', label: f('p153_t7230', '爱尔兰') },
      { value: 'g1230', label: f('p153_t7240', '荷兰') },
      { value: 'g1240', label: f('p153_t7250', '比利时') },
      { value: 'g1250', label: f('p153_t7260', '卢森堡') },
      { value: 'g1260', label: f('p153_t7270', '法国') },
      { value: 'g1270', label: f('p153_t7280', '摩纳哥') },
      { value: 'g1280', label: f('p153_t7290', '罗马尼亚') },
      { value: 'g1290', label: f('p153_t7300', '保加利亚') },
      { value: 'g1300', label: f('p153_t7310', '塞尔维亚') },
      { value: 'g1310', label: f('p153_t7320', '北马其顿') },
      { value: 'g1320', label: f('p153_t7330', '斯洛文尼亚') },
      { value: 'g1330', label: f('p153_t7340', '克罗地亚') },
      { value: 'g1340', label: f('p153_t7350', '黑山') },
      { value: 'g1350', label: f('p153_t7360', '波斯尼亚和黑塞哥维那') },
      { value: 'g1360', label: f('p153_t7370', '阿尔巴尼亚') },
      { value: 'g1370', label: f('p153_t7380', '希腊') },
      { value: 'g1380', label: f('p153_t7390', '意大利') },
      { value: 'g1390', label: f('p153_t7400', '马耳他') },
      { value: 'g1400', label: f('p153_t7410', '梵蒂冈') },
      { value: 'g1410', label: f('p153_t7420', '圣马力诺') },
      { value: 'g1420', label: f('p153_t7430', '西班牙') },
      { value: 'g1430', label: f('p153_t7440', '葡萄牙') },
      { value: 'g1440', label: f('p153_t7450', '安道尔') },
      { value: 'g1990', label: f('p153_t7460', '其他') }]
  },
  {
    value: 'z2',
    label: f('p153_t7470', '亚洲'),
    children: [
      { value: 'g2010', label: f('p153_t7480', '中国') },
      { value: 'g2020', label: f('p153_t7490', '蒙古') },
      { value: 'g2030', label: f('p153_t7500', '朝鲜') },
      { value: 'g2040', label: f('p153_t7510', '韩国') },
      { value: 'g2050', label: f('p153_t7520', '日本') },
      { value: 'g2060', label: f('p153_t7530', '菲律宾') },
      { value: 'g2070', label: f('p153_t7540', '越南') },
      { value: 'g2080', label: f('p153_t7550', '老挝') },
      { value: 'g2090', label: f('p153_t7560', '柬埔寨') },
      { value: 'g2100', label: f('p153_t7570', '缅甸') },
      { value: 'g2110', label: f('p153_t7580', '泰国') },
      { value: 'g2120', label: f('p153_t7590', '马来西亚') },
      { value: 'g2130', label: f('p153_t7600', '文莱') },
      { value: 'g2140', label: f('p153_t7610', '新加坡') },
      { value: 'g2150', label: f('p153_t7620', '印度尼西亚') },
      { value: 'g2160', label: f('p153_t7630', '东帝汶') },
      { value: 'g2170', label: f('p153_t7640', '尼泊尔') },
      { value: 'g2180', label: f('p153_t7650', '不丹') },
      { value: 'g2190', label: f('p153_t7660', '孟加拉国') },
      { value: 'g2200', label: f('p153_t7670', '印度') },
      { value: 'g2210', label: f('p153_t7680', '巴基斯坦') },
      { value: 'g2220', label: f('p153_t7690', '斯里兰卡') },
      { value: 'g2230', label: f('p153_t7700', '马尔代夫') },
      { value: 'g2240', label: f('p153_t7710', '哈萨克斯坦') },
      { value: 'g2250', label: f('p153_t7720', '吉尔吉斯斯坦') },
      { value: 'g2260', label: f('p153_t7730', '塔吉克斯坦') },
      { value: 'g2270', label: f('p153_t7740', '乌兹别克斯坦') },
      { value: 'g2280', label: f('p153_t7750', '土库曼斯坦') },
      { value: 'g2290', label: f('p153_t7760', '阿富汗') },
      { value: 'g2300', label: f('p153_t7770', '伊拉克') },
      { value: 'g2310', label: f('p153_t7780', '伊朗') },
      { value: 'g2320', label: f('p153_t7790', '叙利亚') },
      { value: 'g2330', label: f('p153_t7800', '约旦') },
      { value: 'g2340', label: f('p153_t7810', '黎巴嫩') },
      { value: 'g2350', label: f('p153_t7820', '以色列') },
      { value: 'g2360', label: f('p153_t7830', '巴勒斯坦') },
      { value: 'g2370', label: f('p153_t7840', '沙特阿拉伯') },
      { value: 'g2380', label: f('p153_t7850', '巴林') },
      { value: 'g2390', label: f('p153_t7860', '卡塔尔') },
      { value: 'g2400', label: f('p153_t7870', '科威特') },
      { value: 'g2410', label: f('p153_t7880', '阿拉伯联合酋长国') },
      { value: 'g2420', label: f('p153_t7890', '阿曼') },
      { value: 'g2430', label: f('p153_t7900', '也门') },
      { value: 'g2440', label: f('p153_t7910', '格鲁吉亚') },
      { value: 'g2450', label: f('p153_t7920', '亚美尼亚') },
      { value: 'g2460', label: f('p153_t7930', '阿塞拜疆') },
      { value: 'g2470', label: f('p153_t7940', '土耳其') },
      { value: 'g2480', label: f('p153_t7950', '塞浦路斯') },
      { value: 'g2990', label: f('p153_t7960', '其他') }
    ]
  },
  {
    value: 'z3',
    label: f('p153_t7970', '南美洲'),
    children: [
      { value: 'g3010', label: f('p153_t7980', '哥伦比亚') },
      { value: 'g3020', label: f('p153_t7990', '委内瑞拉') },
      { value: 'g3030', label: f('p153_t8000', '圭亚那') },
      { value: 'g3040', label: f('p153_t8010', '苏里南') },
      { value: 'g3050', label: f('p153_t8020', '厄瓜多尔') },
      { value: 'g3060', label: f('p153_t8030', '秘鲁') },
      { value: 'g3070', label: f('p153_t8040', '巴西') },
      { value: 'g3080', label: f('p153_t8050', '玻利维亚') },
      { value: 'g3090', label: f('p153_t8060', '智利') },
      { value: 'g3100', label: f('p153_t8070', '巴拉圭') },
      { value: 'g3110', label: f('p153_t8080', '乌拉圭') },
      { value: 'g3120', label: f('p153_t8090', '阿根廷') },
      { value: 'g3130', label: f('p153_t8100', '法属圭亚那') },
      { value: 'g3990', label: f('p153_t8110', '其他') }
    ]
  }, {
    value: 'z4',
    label: f('p153_t8120', '北美洲'),
    children: [{ value: 'g4010', label: f('p153_t8130', '美国') },
      { value: 'g4020', label: f('p153_t8140', '加拿大') },
      { value: 'g4030', label: f('p153_t8150', '墨西哥') },
      { value: 'g4040', label: f('p153_t8160', '危地马拉') },
      { value: 'g4050', label: f('p153_t8170', '伯利兹') },
      { value: 'g4060', label: f('p153_t8180', '萨尔瓦多') },
      { value: 'g4070', label: f('p153_t8190', '洪都拉斯') },
      { value: 'g4080', label: f('p153_t8200', '尼加拉瓜') },
      { value: 'g4090', label: f('p153_t8210', '哥斯达黎加') },
      { value: 'g4100', label: f('p153_t8220', '巴拿马') },
      { value: 'g4110', label: f('p153_t8230', '巴哈马') },
      { value: 'g4120', label: f('p153_t8240', '古巴') },
      { value: 'g4130', label: f('p153_t8250', '牙买加') },
      { value: 'g4140', label: f('p153_t8260', '海地') },
      { value: 'g4150', label: f('p153_t8270', '多米尼加共和国') },
      { value: 'g4160', label: f('p153_t8280', '圣基茨和尼维斯') },
      { value: 'g4170', label: f('p153_t8290', '安提瓜和巴布达') },
      { value: 'g4180', label: f('p153_t8300', '多米尼克') },
      { value: 'g4190', label: f('p153_t8310', '圣卢西亚') },
      { value: 'g4200', label: f('p153_t8320', '圣文森特和格林纳丁斯') },
      { value: 'g4210', label: f('p153_t8330', '巴巴多斯') },
      { value: 'g4220', label: f('p153_t8340', '格林纳达') },
      { value: 'g4230', label: f('p153_t8350', '特立尼达和多巴哥') },
      { value: 'g4990', label: f('p153_t8360', '其他' ) }
    ]
  }, {
    value: 'z5',
    label: f('p153_t8370', '非洲'),
    children: [
      { value: 'g5010', label: f('p153_t8380', '埃及') },
      { value: 'g5020', label: f('p153_t8390', '苏丹') },
      { value: 'g5030', label: f('p153_t8400', '南苏丹') },
      { value: 'g5040', label: f('p153_t8410', '利比亚') },
      { value: 'g5050', label: f('p153_t8420', '突尼斯') },
      { value: 'g5060', label: f('p153_t8430', '阿尔及利亚') },
      { value: 'g5070', label: f('p153_t8440', '摩洛哥') },
      { value: 'g5080', label: f('p153_t8450', '毛里塔尼亚') },
      { value: 'g5090', label: f('p153_t8460', '塞内加尔') },
      { value: 'g5100', label: f('p153_t8470', '冈比亚') },
      { value: 'g5110', label: f('p153_t8480', '马里') },
      { value: 'g5120', label: f('p153_t8490', '布基纳法索') },
      { value: 'g5130', label: f('p153_t8500', '几内亚') },
      { value: 'g5140', label: f('p153_t8510', '几内亚比绍') },
      { value: 'g5150', label: f('p153_t8520', '佛得角') },
      { value: 'g5160', label: f('p153_t8530', '塞拉利昂') },
      { value: 'g5170', label: f('p153_t8540', '利比里亚') },
      { value: 'g5180', label: f('p153_t8550', '科特迪瓦') },
      { value: 'g5190', label: f('p153_t8560', '加纳') },
      { value: 'g5200', label: f('p153_t8570', '多哥') },
      { value: 'g5210', label: f('p153_t8580', '贝宁') },
      { value: 'g5220', label: f('p153_t8590', '尼日尔') },
      { value: 'g5230', label: f('p153_t8600', '尼日利亚') },
      { value: 'g5240', label: f('p153_t8610', '乍得') },
      { value: 'g5250', label: f('p153_t8620', '中非共和国') },
      { value: 'g5260', label: f('p153_t8630', '喀麦隆') },
      { value: 'g5270', label: f('p153_t8640', '赤道几内亚') },
      { value: 'g5280', label: f('p153_t8650', '加蓬') },
      { value: 'g5290', label: f('p153_t8660', '刚果（布）') },
      { value: 'g5300', label: f('p153_t8670', '刚果（金）') },
      { value: 'g5310', label: f('p153_t8680', '圣多美和普林西比') },
      { value: 'g5320', label: f('p153_t8690', '安哥拉') },
      { value: 'g5330', label: f('p153_t8700', '赞比亚') },
      { value: 'g5340', label: f('p153_t8710', '马拉维') },
      { value: 'g5350', label: f('p153_t8720', '莫桑比克') },
      { value: 'g5360', label: f('p153_t8730', '博茨瓦纳') },
      { value: 'g5370', label: f('p153_t8740', '纳米比亚') },
      { value: 'g5380', label: f('p153_t8750', '南非共和国') },
      { value: 'g5390', label: f('p153_t8760', '斯威士兰') },
      { value: 'g5400', label: f('p153_t8770', '莱索托') },
      { value: 'g5410', label: f('p153_t8780', '马达加斯加') },
      { value: 'g5420', label: f('p153_t8790', '科摩罗') },
      { value: 'g5430', label: f('p153_t8800', '毛里求斯') },
      { value: 'g5440', label: f('p153_t8810', '埃塞俄比亚') },
      { value: 'g5450', label: f('p153_t8820', '厄立特里亚') },
      { value: 'g5460', label: f('p153_t8830', '索马里') },
      { value: 'g5470', label: f('p153_t8840', '吉布提') },
      { value: 'g5480', label: f('p153_t8850', '肯尼亚') },
      { value: 'g5490', label: f('p153_t8860', '坦桑尼亚') },
      { value: 'g5500', label: f('p153_t8870', '乌干达') },
      { value: 'g5510', label: f('p153_t8880', '卢旺达') },
      { value: 'g5520', label: f('p153_t8890', '布隆迪') },
      { value: 'g5530', label: f('p153_t8900', '塞舌尔') },
      { value: 'g5540', label: f('p153_t8910', '圣赫勒拿岛') },
      { value: 'g5990', label: f('p153_t8920', '其他') }
    ]
  }, {
    value: 'z6',
    label: f('p153_t8930', '大洋洲'),
    children: [
      { value: 'g6010', label: f('p153_t8940', '澳大利亚') },
      { value: 'g6020', label: f('p153_t8950', '新西兰') },
      { value: 'g6030', label: f('p153_t8960', '巴布亚新几内亚') },
      { value: 'g6040', label: f('p153_t8970', '所罗门群岛') },
      { value: 'g6050', label: f('p153_t8980', '瓦努阿图') },
      { value: 'g6060', label: f('p153_t8990', '帕劳') },
      { value: 'g6070', label: f('p153_t9000', '斐济') },
      { value: 'g6080', label: f('p153_t9010', '密克罗尼西亚联邦') },
      { value: 'g6090', label: f('p153_t9020', '马绍尔群岛') },
      { value: 'g6100', label: f('p153_t9030', '基里巴斯') },
      { value: 'g6110', label: f('p153_t9040', '瑙鲁') },
      { value: 'g6120', label: f('p153_t9050', '萨摩亚') },
      { value: 'g6130', label: f('p153_t9060', '汤加') },
      { value: 'g6140', label: f('p153_t9070', '图瓦卢') },
      { value: 'g6150', label: f('p153_t9080', '库克群岛') },
      { value: 'g6160', label: f('p153_t9090', '纽埃') },
      { value: 'g6170', label: f('p153_t9100', '皮特凯恩群岛') },
      { value: 'g6180', label: f('p153_t9110', '托克劳') },
      { value: 'g6990', label: f('p153_t9120', '其他' ) }
    ]
  }
]

export const getContinentText=(val) => {
  if (!Array.isArray(val)||val.length==0) {
    return ''
  }
  let result=''
  const r = allContinent.filter((item) => item.value === val[0])
  if(r.length>0) {
    result=r[0].label
    if(val.length>1) {
      const r1=r[0].children.filter((item) => item.value === val[1])
      if(r1.length>0) {
        result+='/'+r1[0].label
      }
    }
  }

  return result
}

export const getContinentTexts=(val) => {
  if (!Array.isArray(val)||val.length==0) {
    return ''
  }
  const result=val.map(x => getContinentText(x))
  return result

}